.autofris__aboutus {
    position: relative;
}

.autofris__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.autofris__aboutus-overlay img{
    width: 391px;
    height: 315px;
    z-index: 0;
}

.autofris__aboutus-content {
    width: 100%;
    z-index: 2;
}

.autofris__aboutus-content_about {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    font-weight: 500;

    text-align: right;
}

.autofris__aboutus-content_gear {
    margin: 2rem 4rem;
}


.autofris__aboutus-content_gear img {
    height: 210px;
    width: 84px;
}

.autofris__aboutus-content_objective {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    font-weight: 500;

    text-align: left;
}


.autofris__aboutus-content_about p,
.autofris__aboutus-content_objective p {
    margin: 2rem 0;
    color: var(--color-grey);
}

@media screen and (max-width: 2000px) {
    .autofris__aboutus-content_gear img{
        height: 210px;
    }
    .autofris__aboutus-content_about p,
    .autofris__aboutus-content_objective p {
        margin: 2rem 0.5rem;
    }


}

@media screen and (max-width: 1150px) {
    .autofris__aboutus-content {
        flex-direction: column;
    }
    .autofris__aboutus-content_gear {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 650px) {
    .autofris__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
}