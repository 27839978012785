.autofris__navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-dark-blue);
    padding: 1rem 2rem;

}


.autofris__navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.autofris__navbar-logo img {
    width: 200px;
}

.autofris__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    list-style: none;
}

.autofris__navbar-links li{
    margin: 0 1rem;
    cursor: pointer;
}


.autofris__navbar-links li:hover{
   color: var(--color-gold);
}


.autofris__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.autofris__navbar-login a{
    margin: 0 1.5rem;
    text-decoration: none;
    transition: 0.5s ease-in-out;
}

.autofris__navbar-login button {
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    background: var(--color-gold);
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.autofris__navbar-login div{
    width: 1px;
    height: 36px;
    background-color: var(--color-grey);
}

/* mobile */
.autofris__navbar_mobile-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.autofris__navbar_mobile-login a{
    margin: 0 1.5rem;
    text-decoration: none;
    transition: 0.5s ease-in-out;
}

.autofris__navbar_mobile-login button {
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    background: var(--color-gold);
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.autofris__navbar_mobile-login div{
    width: 1px;
    height: 36px;
    background-color: var(--color-grey);
}


/* mobile */

.autofris__navbar-smallscreen {
    display: none;
}

.autofris__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-dark-blue);
    transition: 0.5s ease;

    flex-direction: column;
    z-index: 5;
}

.autofris__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-gold);
    cursor: pointer;

    position: absolute;
    top: 20px;
    right: 20px;
}

.autofris__navbar-smallscreen-links {
    list-style: none;
}


.autofris__navbar-smallscreen-links li {
    margin: 2rem;
    cursor: pointer;
    color: var(--color-gold);
    font-size: 2rem;
    text-align: center;
    font-family: var(--font-base);
}

.autofris__navbar-smallscreen-links li:hover {
    color: var(--color-white);
}


@media screen and (max-width: 2000px) {
    .autofris__navbar-logo img {
        width: 210pxs;
    }
}

@media screen and (max-width: 1150px) {
    .autofris__navbar-links {
        display: none;
    }
    .autofris__navbar-smallscreen {
        display: flex;
    }
}


@media screen and (max-width: 650px) {
    .autofris__navbar {
        padding: 1rem;
    }
    .autofris__navbar-login {
        display: none;
    }
    .autofris__navbar-logo img {
        width: 110pxs;
    }
}

@media screen and (max-width: 350px) {
    .autofris__navbar {
        padding: 1rem;
        overflow: hidden;
    }
    .autofris__navbar-login {
        display: none;
    }
    .autofris__navbar-logo img {
        width: 70pxs;
    }
   .autofris__navbar_mobile-login a{
    font-size: 8px;
   }
}