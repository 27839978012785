.autofris__header {
    
    background: -webkit-linear-gradient(bottom, rgb(101, 138, 157) 0%, rgb(79, 120, 140) 68%, rgb(52, 88, 112) 100%);
    background: -o-linear-gradient(bottom, rgb(101, 138, 157) 0%, rgb(79, 120, 140) 68%, rgb(52, 88, 112) 100%);
    background: -ms-linear-gradient(bottom, rgb(101, 138, 157) 0%, rgb(79, 120, 140) 68%, rgb(52, 88, 112) 100%);
    background: -moz-linear-gradient(bottom, rgb(101, 138, 157) 0%, rgb(79, 120, 140) 68%, rgb(52, 88, 112) 100%);
    background: linear-gradient(to top, rgb(101, 138, 157) 0%, rgb(79, 120, 140) 68%, rgb(52, 88, 112) 100%);
    
}


.autofris__header-h1 {
    font-family: var(--font-base);
    color: var(--color-gold);
    letter-spacing: 0.04rem;
    line-height: 117px;
    font-size: 90px;
}

.autofris__header-img img {
    width: 80%;
}

@media screen and (min-width: 2000px) {
    .autofris__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }
}

@media screen and (max-width: 1150px) {
    .autofris__header-h1 {
        font-size: 90px;
    }
}

@media screen and (max-width: 650px) {
    .autofris__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
}

@media screen and (max-width: 450px) {
    .autofris__header-h1 {
        font-size: 50px;
        line-height: 70px;
    }
}
