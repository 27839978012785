.autofris__bgd{
    background: -webkit-linear-gradient(bottom, rgb(101, 138, 157) 0%, rgb(79, 120, 140) 68%, rgb(52, 88, 112) 100%);
    background: -o-linear-gradient(bottom, rgb(101, 138, 157) 0%, rgb(79, 120, 140) 68%, rgb(52, 88, 112) 100%);
    background: -ms-linear-gradient(bottom, rgb(101, 138, 157) 0%, rgb(79, 120, 140) 68%, rgb(52, 88, 112) 100%);
    background: -moz-linear-gradient(bottom, rgb(101, 138, 157) 0%, rgb(79, 120, 140) 68%, rgb(52, 88, 112) 100%);
    background: linear-gradient(to top, rgb(101, 138, 157) 0%, rgb(79, 120, 140) 68%, rgb(52, 88, 112) 100%);
    
}

.autofris__engr-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 3rem;
}

.autofris__engr-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.autofris__engr-content_quote img {
    width: 47px;
    height: 40px;
    margin: 0 1rem;
}

.autofris__engr-sign {
    width: 100%;
    margin-top: 3rem;
}

.autofris__engr-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32;
    line-height: 41px;
    letter-spacing: 0.04;
    text-transform: capitalize;
    color: var(--color-gold);
}


.autofris__engr-sign img {
    width: 250px;
    margin-top: 3rem;
}

@media screen and (max-width: 2000px) {
    .autofris__engr-sign img {
        width: 370px;
    }
}

@media screen and (max-width: 450px) {
    .autofris__engr-sign img {
        width: 80%;
    }
}
